<template>
    <div class="container">
        <!--begin::Content header-->
        <div
            class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
            <span class="font-weight-bold font-size-3 text-dark-60">
                Hai già un account?
            </span>
            <router-link custom class="font-weight-bold font-size-3 ml-2" :to="{ name: 'login' }">
                Effettua il login!
            </router-link>
        </div>
        <!--end::Content header-->

        <div class="text-center mb-10 mb-lg-20">
            <h3 class="font-size-h1">Registrati</h3>
            <p class="text-muted font-weight-semi-bold">
                Inserisci i tuoi dati per creare un account.<br>
                Se stai registrando un utenza per amministrare un sodalizio già affiliato,<br>contatta la Segreteria
                Nazionale allo <strong>06.68100037</strong> per abbinare il sodalizio all'utenza creata.
            </p>
        </div>

        <div class="row">
            <div class="col-md-6">
                <!--begin::Signup-->
                <div class="login-form login-signin">

                    <!--begin::Form-->
                    <b-form v-if="!form_sent" class="form" @submit.stop.prevent="onSubmit">
                        <b-form-group id="name-group" label="" label-for="name">
                            <label for="name">Nome *:</label>
                            <b-form-input class="form-control form-control-solid h-auto py-5 px-6" id="name" name="name"
                                v-model="$v.form.name.$model" :state="validateState('name')"
                                aria-describedby="name-live-feedback"
                                placeholder="Inserisci il tuo nome"></b-form-input>

                            <b-form-invalid-feedback id="name-live-feedback">
                                <template v-if="typeof form_errors['name'] !== 'undefined'">
                                    <div v-for="error in form_errors['name']">{{ error }}</div>
                                </template>
                                <span v-else>Il nome è un campo obbligatorio.</span>
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group id="surname-group" label="" label-for="surname">
                            <label for="surname">Cognome *:</label>
                            <b-form-input class="form-control form-control-solid h-auto py-5 px-6" id="surname"
                                name="surname" v-model="$v.form.surname.$model" :state="validateState('surname')"
                                aria-describedby="surname-live-feedback"
                                placeholder="Inserisci il tuo cognome"></b-form-input>

                            <b-form-invalid-feedback id="surname-live-feedback">
                                <template v-if="typeof form_errors['surname'] !== 'undefined'">
                                    <div v-for="error in form_errors['surname']">{{ error }}</div>
                                </template>
                                <span v-else>Il cognome è un campo obbligatorio.</span>
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group id="taxcode-group" label="" label-for="taxcode">
                            <label for="taxcode">Codice Fiscale *:</label>
                            <b-form-input class="form-control form-control-solid h-auto py-5 px-6" id="taxcode"
                                name="taxcode" v-model="$v.form.taxcode.$model" :state="validateState('taxcode')"
                                aria-describedby="taxcode-live-feedback"
                                placeholder="Inserisci il tuo codice fiscale"></b-form-input>

                            <b-form-invalid-feedback id="taxcode-live-feedback">
                                <template v-if="typeof form_errors['taxcode'] !== 'undefined'">
                                    <div v-for="error in form_errors['taxcode']">{{ error }}</div>
                                </template>
                                <span v-else>Il codice fiscale è un campo obbligatorio.</span>
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group id="email-group" label="" label-for="email">
                            <label for="email">E-mail *:</label>
                            <b-form-input class="form-control form-control-solid h-auto py-5 px-6" id="email"
                                name="email" type="email" v-model="$v.form.email.$model" :state="validateState('email')"
                                aria-describedby="email-live-feedback"
                                placeholder="Inserisci il tuo indirizzo e-mail"></b-form-input>

                            <b-form-invalid-feedback id="email-live-feedback">
                                <template v-if="typeof form_errors['email'] !== 'undefined'">
                                    <div v-for="error in form_errors['email']">{{ error }}</div>
                                </template>
                                <span v-else>L'indirizzo e-mail è un campo obbligatorio e deve essere un indirizzo
                                    valido.</span>
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group id="username-group" label="" label-for="username">
                            <label for="username">Username *:</label>
                            <b-form-input class="form-control form-control-solid h-auto py-5 px-6" id="username"
                                name="username" v-model="$v.form.username.$model" :state="validateState('username')"
                                aria-describedby="username-live-feedback"
                                placeholder="Inserisci il tuo username"></b-form-input>

                            <b-form-invalid-feedback id="username-live-feedback">
                                <template v-if="typeof form_errors['username'] !== 'undefined'">
                                    <div v-for="error in form_errors['username']">{{ error }}</div>
                                </template>
                                <span v-else>Lo username è un campo obbligatorio.</span>
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group id="password-group" label="" label-for="password">
                            <label for="password">Password *:</label>
                            <b-form-input class="form-control form-control-solid h-auto py-5 px-6" type="password"
                                id="password" name="password" v-model="$v.form.password.$model"
                                :state="validateState('password')" aria-describedby="password-live-feedback"
                                placeholder="Inserisci la password desiderata"></b-form-input>

                            <b-form-invalid-feedback id="passowrd-live-feedback">
                                <template v-if="typeof form_errors['password'] !== 'undefined'">
                                    <div v-for="error in form_errors['password']">{{ error }}</div>
                                </template>
                                <span v-else>La password è oggligatoria.</span>
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group id="password-verify-group" label="" label-for="password-verify">
                            <label for="password">Conferma Password *:</label>
                            <b-form-input class="form-control form-control-solid h-auto py-5 px-6" type="password"
                                id="password-verify" name="password_verify" v-model="$v.form.password_verify.$model"
                                :state="validateState('password_verify')"
                                aria-describedby="password-verify-live-feedback"
                                placeholder="Inserisci la password di conferma"></b-form-input>

                            <b-form-invalid-feedback id="password-verify-live-feedback">
                                <template v-if="typeof form_errors['password_verify'] !== 'undefined'">
                                    <div v-for="error in form_errors['password_verify']">{{ error }}</div>
                                </template>
                                <span v-else>La password di conferma è oggligatoria.</span>
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <div class="alert alert-secondary">
                            <b-form-group label="Confermo di aver letto e di accettare l'informativa sulla privacy: *"
                                v-slot="{ ariaDescribedby }">
                                <b-form-radio-group id="privacy-group" v-model="$v.form.privacy.$model"
                                    :state="validateState('privacy')" :aria-describedby="ariaDescribedby"
                                    name="privacy">
                                    <b-form-radio value="1">Si</b-form-radio>
                                    <b-form-radio value="0">No</b-form-radio>
                                </b-form-radio-group>

                                <b-form-invalid-feedback id="privacy-live-feedback"
                                    :class="{ 'd-block': (validateState('privacy') === false) }">
                                    <template v-if="typeof form_errors['privacy'] !== 'undefined'">
                                        <div v-for="error in form_errors['privacy']">{{ error }}</div>
                                    </template>
                                    <span v-else>Per registrarsi è obbligatorio accettare l'informativa sulla
                                        privacy.</span>
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>

                        <div class="alert alert-secondary">
                            <b-form-group
                                label="Confermo di aver letto e di accettare l'informativa sulla privacy
                                                 e acconsento al trattamento dei miei dati personali per l'invio di materiale promozionale e newsletter *:"
                                v-slot="{ ariaDescribedby }">
                                <b-form-radio-group id="receiveinformation-group"
                                    v-model="$v.form.receiveinformation.$model"
                                    :state="validateState('receiveinformation')" :aria-describedby="ariaDescribedby"
                                    name="receiveinformation">
                                    <b-form-radio value="1">Si</b-form-radio>
                                    <b-form-radio value="0">No</b-form-radio>
                                </b-form-radio-group>

                                <b-form-invalid-feedback id="receiveinformation-live-feedback"
                                    :class="{ 'd-block': (validateState('receiveinformation') === false) }">
                                    <template v-if="typeof form_errors['receiveinformation'] !== 'undefined'">
                                        <div v-for="error in form_errors['receiveinformation']">{{ error }}</div>
                                    </template>
                                    <span v-else>Acconsentire o meno al trattamento dei dati per la ricezione di
                                        materiale promozionale e
                                        newsletter</span>
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>

                        <!--begin::Action-->
                        <div class="form-group d-flex flex-wrap flex-center">
                            <button type="submit" ref="kt_login_signup_submit"
                                class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4">
                                Invia
                            </button>
                            <button type="button" v-on:click.prevent="$router.push('login')"
                                class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4">
                                Annulla
                            </button>
                        </div>
                        <!--end::Action-->
                    </b-form>
                    <!--end::Form-->

                    <div v-if="form_sent">
                        <div role="alert" class="alert alert-success">
                            <div class="alert-text">
                                La registrazione è stata registrata correttamente.<br>
                                Riceverai a breve un link per confermare il tuo indirizzo e-mail nella tua casella ti
                                posta.<br>
                                Ti preghiamo di controllare anche la cartella di spam.<br>
                                Nel caso non arrivasse alcun messaggio procedi con il recupero username/password.
                            </div>
                        </div>

                        <button type="button" v-on:click.prevent="$router.push('/login')"
                            class="btn btn-primary btn-block font-weight-bold font-size-3">
                            Effettua il Login
                        </button>
                    </div>
                </div>
                <!--end::Signup-->
            </div>
            <div class="col-md-6 text-justify">
                <p class="ml-5 text-justify">
                <p>La registrazione deve essere effettuata esclusivamente dal legale rappresentante del sodalizio per il
                    quale si
                    richiede l’affiliazione ACSI.</p>
                <ol class="ml-5">
                    <li>Compila tutti i campi e invia la richiesta.</li>
                    <li>Riceverai una email contenente il link per verificare l’indirizzo di posta elettronica inserito
                        e attivare
                        l’utenza.</li>
                    <li>Compila la domanda di affiliazione e carica tutti i documenti richiesti.</li>
                    <li>Attendi la convalida da parte del Comitato Territoriale ACSI selezionato in fase di
                        affiliazione; oppure
                        contatta il comitato che troverai al seguente link: <a
                            href="https://www.acsi.it/le-nostre-sedi/"
                            target="_blank">https://www.acsi.it/le-nostre-sedi/</a></li>
                </ol>
                </p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
    padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { REGISTER } from "@/core/services/store/auth.module";
import { LOGOUT } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, required, minLength } from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    name: "register",
    data() {
        return {
            form_sent: false,

            form_errors: {},

            form: {
                name: "",
                surname: "",
                taxcode: "",
                email: "",
                username: "",
                password: "",
                password_verify: "",
                privacy: "",
                receiveinformation: "",
            }
        };
    },
    validations: {
        form: {
            name: {
                required,
            },
            surname: {
                required,
            },
            taxcode: {
                required,
            },
            email: {
                required,
                email
            },
            username: {
                required,
                minLength: minLength(3)
            },
            password: {
                required,
                minLength: minLength(3)
            },
            password_verify: {
                required,
                minLength: minLength(3)
            },
            privacy: {
                required,
            },
            receiveinformation: {
                required,
            },
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? (typeof this.form_errors[name] !== "undefined" ? false : ($error ? false : null)) : null;
        },
        resetForm() {
            this.form = {
                name: null,
                surname: null,
                taxcode: null,
                email: null,
                username: null,
                password: null,
                password_verify: null,
                privacy: null,
                receiveinformation: null,
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            const name = this.$v.form.name.$model;
            const surname = this.$v.form.surname.$model;
            const taxcode = this.$v.form.taxcode.$model;
            const email = this.$v.form.email.$model;
            const username = this.$v.form.username.$model;
            const password = this.$v.form.password.$model;
            const password_verify = this.$v.form.password_verify.$model;
            const privacy = this.$v.form.privacy.$model;
            const receiveinformation = this.$v.form.receiveinformation.$model;

            // clear existing errors
            this.$store.dispatch(LOGOUT);

            // set spinner to submit button
            const submitButton = this.$refs["kt_login_signup_submit"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.form_errors = {};

            this.$store
                .dispatch(REGISTER, {
                    name: name,
                    surname: surname,
                    taxcode: taxcode,
                    email: email,
                    username: username,
                    password: password,
                    password_verify: password_verify,
                    privacy: privacy,
                    receiveinformation: receiveinformation,
                })
                .then((response) => {
                    if (typeof response.status !== "undefined" && response.status === 'OK') {
                        this.form_sent = true;
                    }
                })
                .catch((response => {
                    if (response.status === 422) {
                        this.form_errors = JSON.parse(JSON.stringify(this.errors));
                    }
                }));

            submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
            );
        }
    },
    computed: {
        ...mapState({
            errors: state => state.auth.errors
        })
    }
};
</script>
